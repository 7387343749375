import React, { useState } from "react";
import {
  UnstyledButton,
  Flex,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";

import { ChevronsIconRight } from "assets/icon";

export default function SlotTile({ onSelectService, slot, service }) {
  const theme = useMantineTheme();

  const [isHovering, setIsHovering] = useState(false);

  return (
    <UnstyledButton
      sx={{
        paddingTop: rem(8),
        paddingBottom: rem(8),
        paddingLeft: rem(6),
        paddingRight: rem(6),
        "&:hover": {
          backgroundColor: "rgba(44,29,221,0.1)",
        },
        transition: "background-color 0.2s ease-in-out",
        borderRadius: theme.radius.md,
      }}
      onClick={onSelectService}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        miw="100%"
        sx={{
          borderTopWidth: 0,
          borderBottomWidth: 0,
          borderRightWidth: 0,
          borderLeftWidth: 2,
          borderColor: "#2C1DDD",
          borderStyle: "solid",
          paddingLeft: rem(12),
        }}
      >
        <Flex direction="column">
          <Text fz="xs" lineClamp={2}>
            {slot.description || service.serviceName}
          </Text>
          <Text size={"xs"} color="gray">
            {`${slot.duration} min`} - €{slot.price}
          </Text>
        </Flex>
        <ChevronsIconRight
          className="chevron-icon"
          w={rem(18)}
          h={rem(18)}
          color={isHovering ? "#2C1DDD" : theme.colors.dark[3]}
        />
      </Flex>
    </UnstyledButton>
  );
}
