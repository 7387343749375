export const ADD_PARTICIPANT_BODY = "@add-participant-body";
export const SEND_EMAIL_CONFIRMATION_DATA = "@send-email-confirmation-data";
export const TOTAL_PRICE = "@total-price";
export const BOOK_SERVICE_BODY = "@book-service-body";
export const REDUX_STATE = "@redux-state";
export const IDEAL_BANK_NAMES = {
  abn_amro: "ABN AMRO",
  asn_bank: "ASN Bank",
  bunq: "Bunq",
  handelsbanken: "Handelsbanken",
  ing: "ING",
  knab: "Knab",
  rabobank: "Rabobank",
  revolut: "Revolut",
  regiobank: "RegioBank",
  sns_bank: "SNS Bank (De Volksbank)",
  triodos_bank: "Triodos Bank",
  van_lanschot: "Van Lanschot",
};
export const BOOKING_ID = "@booking-id";
export const STRIPE_AC_ID = "@stripe-ac-id";
export const ALL_SERVICE_SELECTED_IDX = "@all-service-selected-idx";

const environment = process.env.REACT_APP_ENVIRONMENT;
// export const BASE_URL = "https://bmcapi-dev.bookzy.app/dev";
export const BASE_URL =
  environment === "PROD"
    ? "https://bmcapi-prod.bookzy.app/prod"
    : "https://bmcapi-dev.bookzy.app";
