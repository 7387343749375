import { useMediaQuery } from "@mantine/hooks";

export default function useIsMobile() {
  const isSmallMobile = useMediaQuery("(max-width: 425px)");
  const isLargeMobile = useMediaQuery("(max-width: 600px)");
  const isTinyMobile = useMediaQuery("(max-width: 320px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 280px)"); // Further reduced screens if needed

  return {
    isSmallMobile,
    isLargeMobile,
    isTinyMobile,
    isVerySmallScreen,
  };
}
