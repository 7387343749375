import moment from "moment";
import { rem, Flex, Image, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { LocationIcon, TimerIcon } from "assets/icon";
import useIsMobile from "hooks/useIsMobile";

function ServiceInfoCard({
  businessDetail,
  serviceData,
  resource,
  isReadMore,
  toggleReadMore,
  selectedStep,
  when,
  where,
}) {
  const { isTinyMobil, isLargeMobile, isSmallMobile, isVerySmallScreen } =
    useIsMobile();
  const resourceWithoutHours = { ...resource };
  delete resourceWithoutHours.available_hours;

  return (
    <div className="section">
      <div className="block info">
        <Flex direction="row" gap="md">
          <Image
            src={serviceData?.servicePhoto}
            height={rem(70)}
            width={rem(110)}
            radius="md"
          />
          <Flex direction="column" gap={rem(4)}>
            <Text
              size={isSmallMobile ? "15px" : "16px"}
              weight={600}
              // style={{
              //   whiteSpace: "nowrap",
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              // }}
            >
              {serviceData?.serviceName}
            </Text>
            {selectedStep !== 1 && (
              <Flex direction="row" gap="sm" align="center">
                <Flex direction="column" gap={rem(5)}>
                  <Text color="gray" size={rem(13)}>
                    Host
                  </Text>
                  <Flex direction="row" align="center" gap="xs">
                    <Image
                      src={
                        resourceWithoutHours?.profilePic &&
                        resourceWithoutHours?.profilePic?.length > 0
                          ? resourceWithoutHours?.profilePic
                          : `https://avatar.iran.liara.run/username?username=${resourceWithoutHours?.fullName
                              ?.split(" ")
                              ?.join("+")}`
                      }
                      height={rem(26)}
                      width={rem(26)}
                      radius="xl"
                    />
                    <Text
                      color="dark.3"
                      weight={600}
                      size={isVerySmallScreen ? rem(10) : rem(14)}
                    >
                      {resourceWithoutHours?.fullName}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        {selectedStep > 1 && (
          <Flex mt="xl" direction="column">
            <div className="item">
              <div className="icon">
                <LocationIcon />
              </div>
              <div className="meta">
                <div className="lbl">Where</div>
                <div className="value">{where?.venueName}</div>
                <div className="text">{where?.address?.address}</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TimerIcon />
              </div>
              <div className="meta">
                <div className="lbl">When</div>
                <div className="value">
                  {moment(when?.day).format("dddd, MMMM D")} at {when?.start} -{" "}
                  {when?.end}
                </div>
              </div>
            </div>
          </Flex>
        )}
        {selectedStep <= 1 && (
          <div className="about-blk">
            <div className="label">Description</div>
            <div className="description">
              {isReadMore
                ? `${serviceData?.description.substring(0, 240)}...`
                : serviceData?.description}
              {""}
              <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? " Read more" : " Show less"}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceInfoCard;
