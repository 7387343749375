import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { Flex, Text } from "@mantine/core";

import { Loader } from "components";
import BookingServiceConfirmation from "views/TicketServices/BookingServiceConfirmation";

import { sendServiceConfirmationEmail } from "../../core/methods";
import {
  ALL_SERVICE_SELECTED_IDX,
  BOOKING_ID,
  BOOK_SERVICE_BODY,
  REDUX_STATE,
} from "../../constants";
import { serviceStep } from "../../store/atoms";
import { updatestate } from "store/reducer";
import {
  useAddServiceBookingMutation,
  useCheckTikkiePayStatusMutation,
} from "store/services/ticketServices";

function ServicePayment() {
  const ticksID = window.localStorage.getItem("ticksID") || "";

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let reduxstate = window.localStorage.getItem(REDUX_STATE);
  reduxstate = reduxstate ? JSON.parse(reduxstate) : {};

  const [, setSelectedStep] = useAtom(serviceStep);
  const [isLoading, setIsLoading] = useState(true);
  const [bookedServiceData, setBookedServiceData] = useState({});
  const [isBookingComplete, setIsBookingComplete] = useState(false);
  const utcOffset = new Date().getTimezoneOffset();

  const [addServiceBooking, { isLoading: isAddServiceBookingLoading }] =
    useAddServiceBookingMutation();
  const [checkTikkiePayStatus, { isLoading: isCheckingTikkiePayStatus }] =
    useCheckTikkiePayStatusMutation();

  const handleSendEmailConfirmation = async (addServiceBookingResponse) => {
    const body = {
      addServiceBookingResponse,
      reduxstate,
      notificationType: "confirmation",
    };

    const [isSuccess] = await sendServiceConfirmationEmail(body);

    if (!isSuccess) {
      throw new Error();
    }

    const newReduxStateForStorage = {
      ...reduxstate,
      totalPrice: 0,
    };
    window.localStorage.setItem(
      REDUX_STATE,
      JSON.stringify(newReduxStateForStorage)
    );
    return;
  };

  const handleServiceBooking = async () => {
    if (!isBookingComplete) {
      const bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY);
      const bookingId = window.localStorage.getItem(BOOKING_ID);
      const body = {
        ...JSON.parse(bookServiceBody),
        bookingId,
        paymentMethod: "tikkie",
        utcOffset,
      };

      const { data, statusCode } = await addServiceBooking(body).unwrap();

      const isSuccess = statusCode === 200;

      if (isSuccess) {
        setBookedServiceData(data);
        await handleSendEmailConfirmation(data.bookingInfo);
        dispatch(updatestate({ serviceBookingReservationId: null }));
        window.localStorage.removeItem(BOOK_SERVICE_BODY);
        window.localStorage.removeItem(BOOKING_ID);
        window.localStorage.removeItem(ALL_SERVICE_SELECTED_IDX);
        setIsBookingComplete(true);
      }
    }
    return;
  };

  const fetchPaymentStatus = async () => {
    const { data, statusCode } = await checkTikkiePayStatus({
      paymentRequestToken: id,
    }).unwrap();

    const isSuccess = statusCode === 200;

    if (isSuccess) {
      if (data?.numberOfPayments > 0) {
        if (!isAddServiceBookingLoading || !isCheckingTikkiePayStatus) {
          handleServiceBooking().then(() => {
            setIsLoading(false);
            setSelectedStep(1);
          });
        }
      } else {
        setTimeout(() => {
          fetchPaymentStatus();
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await fetchPaymentStatus();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onBack = () => {
    setSelectedStep(0);
    navigate(`/${ticksID}`, { replace: true });
  };

  useEffect(() => {
    window.onpopstate = () => {
      onBack();
      return;
    };
  }, []);

  if (isLoading || !isBookingComplete) {
    return (
      <div className="ticket-view-loading">
        <Flex direction="column" gap={16} align="center">
          <Loader size={40} />
          <Text>Confirming payment from tikkie</Text>
        </Flex>
      </div>
    );
  } else {
    return (
      <div className="ticket-booking">
        <BookingServiceConfirmation
          serviceData={reduxstate.serviceData}
          bookingConfirmationData={bookedServiceData.bookingInfo}
          where={reduxstate.serviceSelectedLocation}
        />
      </div>
    );
  }
}

export default ServicePayment;
